<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-12 ">
            <b-card title="Options List">
              <div class="d-flex justify-content-end">
                <BFormInput
                    class ="mb-2" type="search" placeholder="Search..."
                    style="width:50%"
                   />
              </div>
              <b-card-text>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th class="text-center">Sl</th>
                      <th>Question</th>
                      <th>Answer</th>
                      <th class="text-center">Status</th>
                      <th class="text-center">Action</th>
                    </tr>
                    </thead>
                    <tbody v-if="faqs">

                    <tr
                        v-for="(faq, index) in faqs.faq"
                        :key="index"
                    >
                      <td class="align-middle text-center">
                        {{index+1 }}
                      </td>
                      <td>
                        <span>{{
                            faq.question
                          }}</span>
                      </td>
                      <td>
                        <span>{{
                            faq.answer
                          }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span
                            class="badge"
                            :class="
                            faq.status == 1 ? 'badge-success' : 'badge-danger'
                          "
                        >
                          {{ faq.status == 1 ? "Active" : "Inactive" }}
                        </span>
                      </td>
                      <td class="align-middle text-center">
                        <button
                            class="btn btn-primary btn-sm"
                            @click="edit(faq.id)"
                        >
                          Edit
                        </button>
                      </td>

                    </tr>
                    </tbody>
                  </table>

                </div>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
      <div class="col-md-4" >
        <b-card :title="(form.id == '' ? 'Create' : 'Update')">
          <b-card-text
          ><validation-observer ref="loginValidation">
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
            >
              <div class="row">
                <div class="col-md-12">
                  <b-form-group label="Question" label-for="question">
                    <validation-provider
                        #default="{ errors }"
                        name="question"
                        rules="required"
                    >
                      <b-form-input
                          id="question"
                          v-model="form.question"
                          :state="errors.length > 0 ? false : null"
                          name="question"
                          placeholder="Enter The Question"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group label="Answer" label-for="answer">
                    <validation-provider
                        #default="{ errors }"
                        name="answer"
                        rules="required"
                    >
                      <b-form-input
                          id="answer"
                          v-model="form.answer"
                          :state="errors.length > 0 ? false : null"
                          name="answer"
                          placeholder="Enter The Answer"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <!-- submit buttons -->
              <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
              >
              </b-form-checkbox>
              <b-button v-if="form.id == ''"
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
              >
                Create
              </b-button>
              <b-button v-if="form.id"
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="update()"
              >
                Update
              </b-button>
              <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
              >
                Close
              </b-button>
            </b-form>
          </validation-observer></b-card-text
          >
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      form: {
        id: "",
        question: "",
        answer: "",
        status: true,
      },
      faqs: [],
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    async getResults() {
      const faqs = await this.callApi(
          "get",
          "/app/cms/faq/question" ,
      );
      if (faqs.status) {
        this.faqs = faqs?.data;
      }
    },

    Close() {
      this.form.id = "";
      this.form.question = "";
      this.form.answer = "";
      this.form.status = true;
    },

    async edit(id) {
      const faqs = await this.callApi(
          "get",
          "/app/cms/faq/question/"+ id
      );

      if(faqs?.data){
        this.form.id = faqs?.data.faq.id;
        this.form.question = faqs?.data.faq.question;
        this.form.answer = faqs?.data.faq.answer;
        this.form.status = faqs?.data.faq.status ==1? true : false;
      }
    },

    async update(){
      axios
          .patch("/app/cms/faq/question/"+this.form.id, this.form)
          .then((res) => {
            this.getResults();
            this.Close();
            this.s(res.data.message);
          })
          .catch((e) => {
            if (e.response.status === 422) {
              if (e.response.data.errors.question)
                this.e(e.response.data.errors.question[0]);
              if (e.response.data.errors.answer)
                this.e(e.response.data.errors.answer[0]);
              if (e.response.data.errors.status)
                this.e(e.response.data.errors.status[0]);
            }
          });
    },

    async create(){
      axios
          .post("/app/cms/faq/question", this.form)
          .then((res) => {
            this.getResults();
            this.Close();
            this.s(res.data.message);
          })
          .catch((e) => {
            if (e.response.status === 422) {
              if (e.response.data.errors.question)
                this.e(e.response.data.errors.question[0]);
              if (e.response.data.errors.answer)
                this.e(e.response.data.errors.answer[0]);
              if (e.response.data.errors.status)
                this.e(e.response.data.errors.status[0]);
            }
          });
    },


    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.create();
        }
      });
    },
  },
  components: {
    quillEditor,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
  computed: {
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>